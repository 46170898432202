import {
  registerApplication,
  start,
} from 'single-spa/lib/esm/single-spa.min.js';
import { OktaConfig, SubAppOktaConfig } from './utilities';

registerApplication(
  '@sdi-one-core/okta-login-console-app-web',
  () => System.import('@sdi-one-core/okta-login-console-app-web'),
  (location) => true,
  { oktaConfig: OktaConfig },
);

registerApplication(
  '@sdi-one-reporting/neutral-host-console-app-web',
  () => System.import('@sdi-one-reporting/neutral-host-console-app-web'),
  (location) => true,
  { oktaConfig: SubAppOktaConfig },
);

/**********/

start();
